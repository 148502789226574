import "../../index.scss";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { sortProjects } from "../../services/helpers";
import { DefectCard } from "./DefectCard";
import v1, { v2 } from "../../services/axios";
// import ConfirmModal from "../../ui/ConfirmModal";
import InlineConfirmModal from "../../ui/InlineConfirmModal";

function DefectReview({
  report,
  reportId,
  uniqueId,
  queuedProjects,
  setQueuedProjects,
  setProjectsFetched,
  userCanEdit,
  setMessage,
  getOneReport,
}) {
  const [projects, setProjects] = useState([]);
  const [sortedProjects, setSortedProjects] = useState([]);
  const [checkedProjects, setCheckedProjects] = useState([]);
  const [hideQueued, setHideQueued] = useState(
    !!report.submitted_for_classification_at
  );
  const [showConfirmDeleteSelected, setShowConfirmDeleteSelected] =
    useState(false);
  const [showQueuedConfirm, setShowQueuedConfirm] = useState(false);
  const [total, setTotal] = useState(0);

  const { currentPageNumber } = window.PDFViewerApplication.pdfViewer;
  const allQueuedProjects =
    JSON.parse(localStorage.getItem("queued-projects")) || {};
  const classificationDisabled =
    !allQueuedProjects[uniqueId] || !allQueuedProjects[uniqueId].length;
  const deleteDisabled = !checkedProjects.length;

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    if (currentPageNumber && projects.length) {
      const filteredProjects = projects.filter(
        (project) => project.inspection_page_num_start === currentPageNumber
      );
      setSortedProjects(
        sortProjects("pageStart", filteredProjects).sort((a, b) =>
          a.domoreport_project_log_id > b.domoreport_project_log_id ? -1 : 1
        )
      );
    }
  }, [currentPageNumber, projects]);

  useEffect(() => {
    let currentPageQueued = [];
    if (queuedProjects[uniqueId]) {
      currentPageQueued = queuedProjects[uniqueId].filter(
        (queuedProject) => queuedProject.pageStart === currentPageNumber
      );
    }

    setTotal(currentPageQueued.length + sortedProjects.length);
  }, [queuedProjects, sortedProjects, currentPageNumber, uniqueId]);

  async function getProjects() {
    setProjectsFetched(false);
    try {
      const { data, status } = await v1.get(
        `/domoreport/uploader/allProjectsFor/${reportId}`
      );
      if (status === 200) {
        const checked = JSON.parse(localStorage.getItem("checkedProjects"));
        const queued =
          JSON.parse(localStorage.getItem("queued-projects")) || {};

        if (checked && checked.length) {
          setCheckedProjects([...checked]);
        }
        setQueuedProjects(queued);
        setProjects(data.Projects);
        setProjectsFetched(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleCheck(e) {
    const checkedProject = {
      id: e.target.id,
      checked: e.target.checked,
    };

    if (e.target.checked) {
      localStorage.setItem(
        "checkedProjects",
        JSON.stringify([...checkedProjects, checkedProject])
      );
      setCheckedProjects([...checkedProjects, checkedProject]);
    } else {
      const updatedProjects = checkedProjects.filter(
        (project) => project.id !== e.target.id
      );
      setCheckedProjects(updatedProjects);
      localStorage.setItem("checkedProjects", JSON.stringify(updatedProjects));
    }
  }

  async function startClassification(e) {
    e.preventDefault();
    try {
      const queued = JSON.parse(localStorage.getItem("queued-projects"));
      if (queued && queued[uniqueId] && queued[uniqueId].length) {
        const { status } = await v2.post(
          `/domoreports/${uniqueId}/automation/defects/`,
          queued[uniqueId]
        );
        if (status === 201) {
          // localStorage.removeItem("queued-projects");
          setMessage("Projects submitted for classification");
          getOneReport(reportId);
          getProjects();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function toggleShowConfirmDeleteSelected(e) {
    if (e) {
      e.preventDefault();
    }
    setShowConfirmDeleteSelected(!showConfirmDeleteSelected);
  }

  async function deleteSelected() {
    checkedProjects.map(async (input, index) => {
      const { checked, id } = input;

      if (checked) {
        const inputProject = projects.find(
          (project) => project.domoreport_project_log_id === parseInt(id)
        );

        let url = `/domoreport/uploader/project/${id}`;
        if (inputProject && inputProject.defect_id) {
          url = `/domoreport/uploader/project/${id}?defect=${inputProject.defect_id}`;
        }

        try {
          await v1.delete(url);
          if (index === checkedProjects.length - 1) {
            toggleShowConfirmDeleteSelected();
            localStorage.removeItem("checkedProjects");
            setCheckedProjects([]);
            getProjects();
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
  }

  async function deleteQueued() {
    const queued = JSON.parse(localStorage.getItem("queued-projects")) || {};
    delete queued[uniqueId];

    localStorage.setItem("queued-projects", JSON.stringify(queued));
    setQueuedProjects(queued);
    setShowQueuedConfirm(false);
  }

  return (
    <div className={`defect-review ${!userCanEdit ? "no-edit" : ""}`}>
      <InlineConfirmModal
        title="Delete Selected Projects"
        subtext={`Are you sure you want to delete ${checkedProjects.length} projects?`}
        action="Delete"
        variant="danger"
        show={showConfirmDeleteSelected}
        close={toggleShowConfirmDeleteSelected}
        handleSubmit={deleteSelected}
      />

      <InlineConfirmModal
        title="Delete Queued Projects"
        subtext={`Are you sure you want to delete all ${
          queuedProjects[uniqueId] && queuedProjects[uniqueId].length
        } queued projects for this report? This cannot be undone and all work will be lost.`}
        action="Delete"
        variant="danger"
        show={showQueuedConfirm}
        close={() => setShowQueuedConfirm(false)}
        handleSubmit={deleteQueued}
      />

      <h3 className="md-diy-sidebar-header mb-0">Defects Review</h3>

      <div className="defects-actions mb-2">
        <button
          className="btn-primary"
          disabled={classificationDisabled}
          onClick={startClassification}
        >
          Start Classification
        </button>
        <button
          className="btn-danger"
          disabled={deleteDisabled}
          onClick={toggleShowConfirmDeleteSelected}
        >
          Delete Selected
        </button>
      </div>

      <div className="defects-actions">
        {queuedProjects[uniqueId] && !!queuedProjects[uniqueId].length && (
          <button
            className="btn-secondary"
            onClick={() => setHideQueued(!hideQueued)}
          >
            {hideQueued ? "Show" : "Hide"} Queued
          </button>
        )}
        {queuedProjects[uniqueId] && !!queuedProjects[uniqueId].length && (
          <button
            className="btn-danger"
            onClick={() => setShowQueuedConfirm(true)}
          >
            Delete Queued
          </button>
        )}
      </div>

      <p className="page-header">
        Page {currentPageNumber} - {total} defect(s)
      </p>

      {!hideQueued &&
        queuedProjects[uniqueId] &&
        queuedProjects[uniqueId].map((queuedProject, index) => {
          if (queuedProject.pageStart === currentPageNumber) {
            return (
              <DefectCard
                key={`${index}_${queuedProject.text}`}
                index={index}
                project={queuedProject}
                isQueued={true}
                uniqueId={uniqueId}
                getProjects={getProjects}
                setQueuedProjects={setQueuedProjects}
                defaultChecked={false}
                handleCheck={() => {
                  return null;
                }}
              />
            );
          }
        })}

      {sortedProjects.map((project, index) => {
        const defaultChecked = checkedProjects.find(
          (checkedProject) =>
            parseInt(checkedProject.id) === project.domoreport_project_log_id
        );

        return (
          <DefectCard
            index={index}
            key={project.domoreport_project_log_id}
            project={project}
            isQueued={false}
            uniqueId={uniqueId}
            getProjects={getProjects}
            defaultChecked={defaultChecked}
            setQueuedProjects={setQueuedProjects}
            handleCheck={handleCheck}
          />
        );
      })}

      {total <= 0 && (
        <div>
          <p>No defects identified</p>
        </div>
      )}
    </div>
  );
}
export default withRouter(DefectReview);
